import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const authModule = {
  state: () => ({
    PKH: null,
    formattedPKH: null,
    balance: null,
  }),
  mutations: {
    setPKH(state, data) {
      state.PKH = data;
      state.formattedPKH = data.slice(0, 5) + '...' + data.slice(-5);
    },
    
    disconnect(state) {
      state.PKH = null;
    },
  },
  actions: {
    disconnect({ state, commit, rootState }) {
      rootState.global.wallet.disconnect();
      commit('disconnect')
      location.reload();
    },
    setBalance({ commit, state }, data) {
      console.log('setBalance: ' + data)
      state.balance = data;
    },
  }
}

export default authModule

import axios from "axios";
import BigNumber from "bignumber.js";
import { gql, GraphQLClient } from 'graphql-request'
import { Dex } from './Dex'

function processIconUrl(imgUrl) {
  try {
    const parsedUrl = new URL(imgUrl);
    if (parsedUrl.protocol === "ipfs:") {
      return `https://cloudflare-ipfs.com/ipfs${parsedUrl.pathname}`
    } else {
      return imgUrl;
    }
  } catch (e) {
    return imgUrl;
  }
}

function normalizeTokenFromApi(t) {
  return {
    ... t.info,
    id: t.id,
    type: t.type,
    address: t.address,
    fa2Id: t.fa2Id,
    thumbnailUriHttp: processIconUrl(t.info.thumbnailUri)
  }
}

const globalModule = {
  state: () => {
    const isLocal = window.location.hostname.indexOf('localhost') >= 0;
    return {
      pageLoading: true,
      showModal: false,
      showMobileMenu: false,
      showFooter: true,
      modalData: null,
      settingsData: null,
      smartContracts: [],
      buckets: [],
      networks: ['mainnet', 'testnet',].concat(isLocal ? ['localnet'] : []),
      network: null,
      mainSettingsData: {
        rpcURL: 'https://mainnet.api.tez.ie',
        apiUrl: 'https://api.flamedex.io/api',
        routerUrl: 'https://api.flamedex.io',
        tzViewer: 'https://better-call.dev/mainnet',
        walletName: 'FlameDex',
        walletNetworkType: 'mainnet',
        uberPool: 'KT1PRtrP7pKZ3PSLwgfTwt8hD39bxVojoKuX',
        contracts: []
      },
      testnetSettingsData: {
        rpcURL: 'https://granadanet.smartpy.io',
        apiUrl: 'https://api-dev.flamedex.io/api',
        routerUrl: 'https://api-dev.flamedex.io',
        tzViewer: 'https://better-call.dev/granadanet',
        walletName: 'FlameDex Test',
        walletNetworkType: 'granadanet',
        uberPool: 'KT1CL1oeLNQL8B2FbLvbHMN3KuDgUYPnzfSa',
        contracts: []
      },
      localnetSettingsData: {
        rpcURL: 'http://localhost:8733',
        routerUrl: 'http://192.168.88.250:3000',
        apiUrl: 'http://192.168.88.250:4000',
        tzViewer: 'http://localhost:8000/sandboxnet',
        walletName: 'localnet',
        walletNetworkType: 'custom',
        lambdaView: 'KT1CHc7LLaNM9YKmNZJH2T8tSPU96afkjSqR',
        uberPool: 'KT1MR9ktWtow4QGyDH1EAMs2c2RYmSWEWX1a',
      },
      tezos: null,
      dex: null,
      wallet: null,
      PENNY: 0.000001,
      LP_TOKEN_DECIMALS: 9,
      slippageTolerancePercents: [0.5, 1, 3],
      slippageToleranceSelected: 0.5,
      tokenTypes: [{type: 'fa12', label: 'FA1.2'}, {type: 'fa2', label: 'FA2'}, {type: 'tz', label: 'XTZ'}],
      menuItems: [
        {
          id: 'swap',
          title: 'Swap'
        },
        {
          id: 'invest',
          title: 'Liquidity'
        },
        {
          id: 'pool_list',
          title: 'Pools'
        },
      ],
      investMenuItems: [
        {
          id: 'investAdd',
          title: 'Add'
        },
        {
          id: 'investRemove',
          title: 'Remove'
        },
        {
          id: 'investCreate',
          title: 'Create'
        },
      ],
      subMenuSelected: {},
      subMenuSelectedChildren: {},
      currentRoute: [],
      graphqlClient: null

    }
  },
  mutations: {
    setTezos(state, data) {
      console.log('Set tezos')
      state.tezos = data;
      state.dex = new Dex(state.tezos, state.wallet, state.settingsData.lambdaView, state.settingsData.uberPool)
    },
    setNetwork(state, data) {
      localStorage.setItem('network', data);
      state.network = data;

      if (data === "mainnet") {
        state.settingsData = state.mainSettingsData
      } else if (data === "testnet") {
        state.LP_TOKEN_DECIMALS = 6;
        state.settingsData = state.testnetSettingsData
      } else if (data === "localnet") {
        state.settingsData = state.localnetSettingsData
      }

      state.graphqlClient = new GraphQLClient(state.settingsData.apiUrl);
    },
    setWallet(state, data) {
      console.log('Set wallet')
      state.wallet = data;
    },
    setSettingsData(state, data) {
      state.settingsData = data;
    },
    setShowFooter(state, data) {
      state.showFooter = data;
    },
    setShowMobileMenu(state, data) {
      state.showMobileMenu = data;
    },
    updatePageLoading(state, data) {
      state.pageLoading = data;
    },
    openModal(state, data) {
      state.showModal = true;
      state.modalData = data;
      document.body.classList.add('modalopen')
    },
    closeModal(state) {
      state.showModal = false;
      state.modalData = null;
      document.body.classList.remove('modalopen')
    },
    setSlippageToleranceSelected(state, data) {
      state.slippageToleranceSelected = data;
    },
    setSubMenuSelected(state, data) {
      state.subMenuSelected = data;
    },
    subMenuSelectedChildren(state, data) {
      state.subMenuSelectedChildren = data;
    },
  },
  actions: {
    async getSmartContracts({ commit, state }, data) {
      console.log('axios call started')

      function promisify() {
        return new Promise(resolve => setTimeout(() => resolve('AXIOS CALL'), 3000))
      }

      const tokensPage = await state.graphqlClient.request(gql`
        {
          tokens {
            items {
              id
              type
              address
              fa2Id
              info {
                name
                symbol
                decimals
                thumbnailUri
                verified
              }
            }
          }
        }
      `)

      state.smartContracts = tokensPage.tokens.items.map(t => {
        if (t.info === undefined || t.info === null || t.info.symbol === undefined || t.info.symbol === null || t.info.symbol.length === 0 || t.info.name === null || t.info.name.length == 0) {
          return null;
        }
        return normalizeTokenFromApi(t)
      }).filter(t => t !== null)
      console.log(`Loaded tokens: ${JSON.stringify(state.smartContracts)}`)
      setTimeout(() => state.getSmartContracts(state), 15000)
    },

    async calcRoute({ commit, state }, data) {
      console.log('calcRoute: ' + JSON.stringify(data))
      const fromToken = data.from;
      const toToken = data.to;
      const amt = data.amt;

      const route = await axios.get(state.settingsData.routerUrl + "/route", {
        params: {
          from: fromToken,
          to: toToken,
          amt: "" + amt
        }
      });
      state.currentRoute = route.data;
      return route.data;
    },

    async fetchBuckets({ commit, state }, data) {
      const pairsPage = await state.graphqlClient.request(gql`
        {
          pairs {
            items {
              id
              tokenA {
                id
                type
                address
                fa2Id
                info {
                  name
                  symbol
                  decimals
                  verified
                  decimals
                  thumbnailUri
                }
              }
              reserveA
              tokenB {
                id
                type
                address
                fa2Id
                info {
                  name
                  symbol
                  decimals
                  verified
                  decimals
                  thumbnailUri
                }
              }
              reserveB
              totalSupply
              updatedAt
            }
          }
        }
      `)
      const buckets = pairsPage.pairs.items
      .filter((pair) => pair.tokenA.info !== null && pair.tokenA.info !== undefined && pair.tokenB.info !== null && pair.tokenB.info !== undefined)
      .map((pair) => {
        const pairId = pair.id.split(':')[1];
        return {
          symbol: pair.tokenA.info.symbol + ' - ' + pair.tokenB.info.symbol,
          bucketId: pairId,
          reserveA: new BigNumber(pair.reserveA),
          reserveB: new BigNumber(pair.reserveB),
          totalSupply: new BigNumber(pair.totalSupply),
          tokenA: normalizeTokenFromApi(pair.tokenA),
          tokenB: normalizeTokenFromApi(pair.tokenB),
        }
      })
      
      state.buckets = buckets;
      
      return buckets.data;
    }
  }

};

export default globalModule

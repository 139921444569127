import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import customConsole from '@/mixins/console'
Vue.use(customConsole);

import Messages from './mixins/messages';
Vue.mixin(Messages);

import utils from './mixins/utils';
Vue.mixin(utils);

import axios from 'axios'
Vue.prototype.$axios = axios;


import vuetify from './plugins/vuetify'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

//- build your mixin
import BigNumber from "bignumber.js";
import { compose } from "@taquito/taquito";
import { tzip16 } from "@taquito/tzip16";
import { tzip12 } from "@taquito/tzip12";
import { gql, GraphQLClient } from 'graphql-request';

export default {
  computed: {
    tezos() {
      return this.$store.state.global.tezos
    },
    ttTezos() {
      return this.$store.state.global.tezos.wallet.at(this.$store.state.global.settingsData.uberPool)
    },
    graphqlClient() {
      return this.$store.state.global.graphqlClient;
    },
    wallet() {
      return this.$store.state.global.wallet
    },
    PKH() {
      return this.$store.state.auth.PKH
    },
    feeMultiplicator() {
      return 1000 - this.$store.state.exchange.feePercent * 10
    },
    LP_TOKEN_DECIMALS() {
      return this.$store.state.global.LP_TOKEN_DECIMALS
    },
    dex() {
      return this.$store.state.global.dex
    },
    lambdaView() {
      return this.$store.state.global.settingsData.lambdaView
    },
    settingsData() {
      return this.$store.state.global.settingsData
    },
  },
  methods: {
    numFormat(num) {
      let number = parseInt(num)
      if (number < 10) {
        return '0' + num
      }
      return num

    },
    toNat(amount, token) {
      return new BigNumber(amount)
        .times(10 ** token.decimals)
        .integerValue(BigNumber.ROUND_DOWN);
    },
    fromNat(amount, token) {
      return new BigNumber(amount).div(10 ** token.decimals);
    },
    toTokenAmountString(val, decimals) {
      return val.shiftedBy(-decimals).decimalPlaces(decimals)
    },
    tokenIdString(tokenInfo) {
      if (tokenInfo.type === 'tz') {
        return 'tz';
      } else if (tokenInfo.type === 'fa12') {
        return `fa12:${tokenInfo.address}`;
      } else if (tokenInfo.type === 'fa2') {
        return `fa2:${tokenInfo.address}:${tokenInfo.fa2Id}`;
      } else {
        throw new Error('Invalid token info')
      }
    },
    exchangeRate(leftTokenAmount, leftTokenDecimals, rightTokenAmount, rightTokenDecimals) {
      return rightTokenAmount.div(leftTokenAmount).shiftedBy(leftTokenDecimals - rightTokenDecimals);
    },
    calculateFee(route) {
      if (route.length == 0) return 0;
      let fee = 0.003;
      let cur = 1000000;
      for (let i = 1; i < route.length; i++) {
        cur = cur - cur * fee
        console.log('cur: ' + cur)
      }

      return (1000000 - cur) / 1000000;
    },
    tzToMutez(tz) {
      return this.tezos.format("tz", "mutez", tz);
    },
    mutezToTz(mutez) {
      return this.tezos.format("mutez", "tz", mutez);
    },
    async estimateTezToToken(tezAmount, dexStorage, contractObj) {

      if (!tezAmount) return new BigNumber(0);

      const mutezAmount = this.tzToMutez(tezAmount);
      const tezInWithFee = mutezAmount.times(this.feeMultiplicator);
      const numerator = tezInWithFee.times(dexStorage.storage.token_pool);
      const denominator = new BigNumber(dexStorage.storage.tez_pool)
        .times(1000)
        .plus(tezInWithFee);
      const tokensOut = numerator.idiv(denominator);

      return this.fromNat(tokensOut, contractObj);
    },
    async estimateTokenToTez(tokenAmount, dexStorage, contractObj) {

      if (!tokenAmount) return new BigNumber(0);

      const tokensIn = this.toNat(tokenAmount, contractObj);
      const tokenInWithFee = tokensIn.times(this.feeMultiplicator);
      const numerator = tokenInWithFee.times(dexStorage.storage.tez_pool);
      const denominator = new BigNumber(dexStorage.storage.token_pool)
        .times(1000)
        .plus(tokenInWithFee);

      const mutezOut = numerator.idiv(denominator);
      return this.mutezToTz(mutezOut);
    },
    async estimateTezToTokenInverse(tokenAmount, dexStorage, contractObj) {
      if (!tokenAmount) return new BigNumber(0);

      const tokensOut = this.toNat(tokenAmount, contractObj);

      const numerator = new BigNumber(dexStorage.storage.tez_pool)
        .times(1000)
        .times(tokensOut);
      const denominator = new BigNumber(dexStorage.storage.token_pool)
        .minus(tokensOut)
        .times(this.feeMultiplicator);
      const mutezIn = numerator.idiv(denominator).plus(1);

      return this.mutezToTz(mutezIn);
    },
    async estimateTokenToTezInverse(tezAmount, dexStorage, contractObj) {
      if (!tezAmount) return new BigNumber(0);

      const mutezOut = this.tzToMutez(tezAmount);

      const numerator = new BigNumber(dexStorage.storage.token_pool)
        .times(1000)
        .times(mutezOut);
      const denominator = new BigNumber(dexStorage.storage.tez_pool)
        .minus(mutezOut)
        .times(this.feeMultiplicator);
      const tokensIn = numerator.idiv(denominator).plus(1);

      return this.fromNat(tokensIn, contractObj);
    },
    getContract(address) {
      return this.tezos.contract.at(address);
    },
    async getDexStorage(contractAddress) {
      const contract = await this.getContract(contractAddress);
      return contract.storage()

    },
    async getDexShares(address, exchange) {
      const storage = await this.getDexStorage(exchange);
      const ledger = storage.storage.ledger || storage.storage.accounts;
      const val = await ledger.get(address);
      if (!val) return null;

      const unfrozen = new BigNumber(val.balance);
      const frozen = new BigNumber(val.frozen_balance);

      return {
        unfrozen,
        frozen,
        total: unfrozen.plus(frozen),
      };
    },
    isUnsafeAllowanceChangeError(err) {
      try {
        if (
          ["UnsafeAllowanceChange", "FA1.2_UnsafeAllowanceChange"].includes(
            err.message
          )
        ) {
          return true;
        }

        if (
          err.errors.some(
            (e) =>
              e.with.int === "23" ||
              ["UnsafeAllowanceChange", "FA1.2_UnsafeAllowanceChange"].includes(
                e.with.string
              ) ||
              ["UnsafeAllowanceChange", "FA1.2_UnsafeAllowanceChange"].includes(
                e.with?.args[0].string
              )
          )
        ) {
          return true;
        }

        return false;
      } catch {
        return false;
      }
    },
    approveToken(token, tokenContract, from, to, amount) {

      if (token.type === 'fa2') {
        return tokenContract.methods.update_operators([
          {
            add_operator: {
              owner: from,
              operator: to,
              token_id: token.fa2Id,
            },
          },
        ]);
      } else if (token.type === 'fa12') {
        return tokenContract.methods.approve(to, amount);
      }
    },
    deapproveFA2(batch, token, tokenContract, from, to) {
      if (token.type === 'fa2') {
        return batch.withTransfer(
          tokenContract.methods
            .update_operators([
              {
                remove_operator: {
                  owner: from,
                  operator: to,
                  token_id: token.fa2Id,
                },
              },
            ])
            .toTransferParams()
        );
      } else {
        return batch;
      }

    },
    estimateShares(tezAmount, dexStorage) {
      if (!tezAmount) return new BigNumber(0);

      return this.tzToMutez(tezAmount)
        .integerValue(BigNumber.ROUND_DOWN)
        .times(dexStorage.total_supply)
        .div(dexStorage.tez_pool)
        .integerValue(BigNumber.ROUND_DOWN);
    },
    estimateSharesInverse(
      tokenAmount,
      dexStorage,
      token
    ) {
      if (!tokenAmount) return new BigNumber(0);

      return this.toNat(tokenAmount, token)
        .integerValue(BigNumber.ROUND_DOWN)
        .times(dexStorage.total_supply)
        .div(dexStorage.token_pool)
        .integerValue(BigNumber.ROUND_DOWN);
    },
    estimateInTezos(shares, dexStorage) {
      if (!shares) return new BigNumber(0);

      return this.mutezToTz(
        new BigNumber(shares)
          .times(dexStorage.tez_pool)
          .div(dexStorage.total_supply)
          .integerValue(BigNumber.ROUND_DOWN)
      );
    },
    estimateInTokens(shares, dexStorage, token) {
      if (!shares) return new BigNumber(0);

      let nat = new BigNumber(shares)
        .times(dexStorage.token_pool)
        .div(dexStorage.total_supply)
        .integerValue(BigNumber.ROUND_DOWN);

      if (
        new BigNumber(shares)
          .times(dexStorage.token_pool)
          .isGreaterThan(nat.times(dexStorage.total_supply))
      ) {
        nat = nat.plus(1);
      }

      return this.fromNat(nat, token);
    },
    estimateToTezos(
      tokenAmount,
      dexStorage,
      token
    ) {
      if (!tokenAmount) return new BigNumber(0);

      const shares = this.estimateSharesInverse(tokenAmount, dexStorage, token);
      let tezAmount = this.estimateInTezos(shares, dexStorage);

      while (!toTokens(tezAmount, dexStorage, token).isEqualTo(tokenAmount)) {
        tezAmount = tezAmount.plus(this.$store.state.global.PENNY);
      }
      return tezAmount;
    },
    toTokens(tezAmount, dexStorage, token) {
      const shares = estimateShares(tezAmount, dexStorage);
      return this.estimateInTokens(shares, dexStorage, token);
    },
    toValidAmount(amount) {
      return amount && amount.isFinite() && amount.isGreaterThan(0)
        ? amount.toFixed()
        : "";
    },
    sharesFromNat(val) {
      return new BigNumber(val).div(10 ** this.LP_TOKEN_DECIMALS);
    },
    sharesToNat(val) {
      return new BigNumber(val)
        .times(10 ** this.LP_TOKEN_DECIMALS)
        .integerValue(BigNumber.ROUND_DOWN);
    },
    async getTokenMetadata(
      contractAddress,
      fa2TokenId
    ) {
      const tokenId = fa2TokenId ?? 0;

      const contract = await this.tezos.contract.at(contractAddress, compose(tzip12, tzip16));

      let tokenData;
      let latestErrMessage;

      /**
       * Try fetch token data with TZIP12
       */
      try {
        tokenData = await contract.tzip12().getTokenMetadata(tokenId);
      } catch (err) {
        latestErrMessage = err.message;
      }

      /**
       * Try fetch token data with TZIP16
       * Get them from plain tzip16 structure/scheme
       */
      if (!tokenData || Object.keys(tokenData).length === 0) {
        try {
          const { metadata } = await contract.tzip16().getMetadata();
          tokenData = metadata;
        } catch (err) {
          latestErrMessage = err.message;
        }
      }

      if (!tokenData) {
        tokenData = {};
      }

      return {
        decimals: tokenData.decimals ? +tokenData.decimals : 0,
        symbol: tokenData.symbol || contractAddress,
        name: tokenData.name || tokenData.symbol || "Unknown Token",

      };
    },
    async getNewTokenData(
      accountPkh,
      tokenType,
      tokenAddress,
      tokenId
    ) {
      console.log(`Fetch new token info tokenType = ${tokenType}, addr = ${tokenAddress}, id = ${tokenId}`)
      let nat;
      let shouldTryGetMetadata = true;

      let metadata = null;
      let decimals = null;
      let tokenName = null;
      let symbol = null;

      // first check our database
      const tokenInfoRes = await this.graphqlClient.request(gql`
        query getToken($id: String!) {
          tokenInfo(id: $id) {
            id
            type
            address
            fa2Id
            name
            symbol
            decimals
            thumbnailUri
            verified
          }
        }
      `, {id: this.tokenIdString({type: tokenType, address: tokenAddress, fa2Id: tokenType === 'fa2' ? tokenId : undefined })});
      console.log(tokenInfoRes)
      if (tokenInfoRes.tokenInfo !== null) {
        return tokenInfoRes.tokenInfo;
      }

      switch (tokenType) {
        case 'fa12':
          const contract = await this.getContract(tokenAddress);

          //try {
          //  nat = await contract.views.getBalance(accountPkh).read();
          //} catch {
          shouldTryGetMetadata = true;
          //}

          if (shouldTryGetMetadata) {
            metadata = await this.getTokenMetadata(tokenAddress);

            decimals = parseInt(metadata.decimals);
            tokenName = metadata.name;
            symbol = metadata.symbol;
          }

          return {
            name: tokenName,
            symbol: symbol,
            address: tokenAddress,
            dexContract: null,
            type: tokenType,
            fa2Id: tokenId,
            decimals: decimals,
          };

        case 'fa2':
          const fa2Contract = await this.getContract(tokenAddress);

          if (typeof tokenId !== "number" || isNaN(tokenId)) {
            throw new Error("FA2 token type requires token ID");
          }

          try {
            const response = await fa2Contract.views
              .balance_of([{ owner: accountPkh, token_id: tokenId }])
              .read(this.lambdaView);
            nat = response[0].balance;
          } catch {
            shouldTryGetMetadata = false;
          }


          if (shouldTryGetMetadata) {
            metadata = await this.getTokenMetadata(tokenAddress, tokenId);

            decimals = metadata.decimals;
            tokenName = metadata.name;
            symbol = metadata.symbol;
          }

          return {
            name: tokenName,
            symbol: symbol,
            address: tokenAddress,
            dexContract: null,
            type: tokenType,
            fa2Id: tokenId,
            decimals: decimals,
          };

        default:
          throw new Error("Token type not supported");
      }
    },
    getNetwork() {
      const netId = localStorage.getItem("network");
      if (!netId) throw new Error("No network ID");

      if (netId === 'mainnet') {
        return this.$store.state.global.mainSettingsData
      }
      if (netId === 'florencenet') {
        return this.$store.state.global.florenceSettingsData
      }
    },
    debounce(func, delay = 300) {
      let debounceTimer;
      return function () {
        // console.log("debouncing call..");
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
        // console.log("..done");
      };
    }
  }
};



<template>
	<v-card>
		<v-footer
				v-bind="{absolute:true}"
				:padless="padless"
		>
			<v-card
					flat
					tile
					width="100%"
					class="red lighten-1 text-center"
			>
				<v-card-text class="white--text">
					{{ new Date().getFullYear() }} <strong>© Flame &#x1F525; DEX</strong>
				</v-card-text>
			</v-card>
		</v-footer>


	</v-card>
</template>

<script>
    export default {
        data: () => ({
            icons: [
                'mdi-home',
                'mdi-email',
                'mdi-calendar',
                'mdi-delete',
            ],
            padless: true,
        }),
        computed: {

        },
    }
</script>

<style lang="scss" scoped>

	.footer {
		&-container {
			background-color: green;
		}
	}
</style>
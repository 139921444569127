<template>
  <v-select
    v-model="selectedNetwork"
    :items="networksVerbose"
    item-text="verbose"
    item-value="id"
    dense
    label="Input"
    single-line
    class="select"
    color="#9575cd"
    background-color="#9575cd"
    height="48"
    max-width="210"
    x-large
    rounded
    dark
    style="text-decoration: none; color: #fff; font-weight: 600;letter-spacing: normal;font-size: 20px; position: relative; top: -4px;"
  ></v-select>
</template>

<script>
export default {
  name: "Settings",
  props: {},
  components: {},
  mixins: [],
  mounted() {},
  destroyed() {},
  computed: {
    tezos() {
      return this.$store.state.global.tezos;
    },
    networks() {
      return this.$store.state.global.networks;
    },
    networksVerbose() {
      let networksVerbose = [];
      for (const network of this.networks) {
        networksVerbose.push({
          verbose: this.getVerbose(network),
          id: network,
        });
      }

      return networksVerbose;
    },
  },
  data() {
    return {
      selectedNetwork: this.$store.state.global.network,
    };
  },
  methods: {
    getVerbose(id) {
      if (id === "mainnet") {
        return "Mainnet";
      } else if (id === "testnet") {
        return "Testnet";
      } else if (id === "localnet") {
        return "Localnet";
      }
    },
  },
  watch: {
    selectedNetwork(newValue) {
      this.$store.commit("setNetwork", newValue);
      location.reload();
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/scss/global.scss";

.select {
  margin-top: 24px !important;
  max-width: 210px;
}
</style>


const liquidityAddModule = {
  namespaced: true,
  state: () => ({
    tezToken: {},
    selectedToken: {},
    selectedBucket: null,
    tezAmount: null,
    selectedTokenAmount: null,
    swapRate: null,
    tezTokenBalance: null,
    selectedTokenBalance: null,


  }),
  mutations: {
    setSelectedTokenBalance(state, data) {
      state.selectedTokenBalance = data;
    },
    setTezTokenBalance(state, data) {
      state.tezTokenBalance = data;
    },
    setTezToken(state, data) {
      state.tezToken = data;
    },
    setSelectedBucket(state, data) {
      state.selectedBucket = data;
    },
    setTezAmount(state, data) {
      state.tezAmount = data;
    },
    setSelectedTokenAmount(state, data) {
      state.selectedTokenAmount = data;
    },
    setSwapRate(state, data) {
      state.swapRate = data;
    },
    setContractObjDecimal(state, data) {
      data.isTo ? state.contractToObj.decimals = data.decimals : state.contractFromObj.decimals = data.decimals
    },
  },
};

export default liquidityAddModule
const liquidityCreateModule = {
    namespaced: true,
    state: () => ({
        tezToken: {},
        selectedToken: {},
        tezAmount: null,
        selectedTokenAmount: null,
        tokenTypeSelected: null,
        tokenID: 0,
        tezTokenBalance: null,
        selectedTokenBalance: null,
        swapRate: null,
        clearBalanceIncrement:0,
        clearTokenAddressIncrement:0
    }),
    mutations: {
        setTezToken(state, data) {
            state.tezToken = data;
        },
        setSelectedToken(state, data) {
            state.selectedToken = data;
        },
        setTezAmount(state, data) {
            state.tezAmount = data;
        },
        setSelectedTokenAmount(state, data) {
            state.selectedTokenAmount = data;
        },
        setTokenTypeSelected(state, data) {
            state.tokenTypeSelected = data;
        },
        setTokenID(state, data) {
            state.tokenID = data;
        },
        setSelectedTokenBalance(state, data) {
            state.selectedTokenBalance = data;
        },
        setTezTokenBalance(state, data) {
            state.tezTokenBalance = data;
        },
        setSwapRate(state, data) {
            state.swapRate = data;
        },
        clearBalance(state) {
            state.selectedTokenBalance = 0;
            state.clearBalanceIncrement += 1;
        },
        clearSelectedToken(state) {
            state.selectedToken = {};
        },
        clearTokenAddress(state) {
            state.clearTokenAddressIncrement += 1;
        },

    },
};

export default liquidityCreateModule
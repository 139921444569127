
const exchangeModule = {
    namespaced: true,
    state: () => ({
        feePercent: 0.3,
        contractFromObj: {},
        contractToObj: {},
        amountTo: null,
        amountFrom: null,
        minimumTo: null,
        swapRate: null,
        fromTokenBalance: null,
        toTokenBalance: null,
    }),
    mutations: {
        setFromTokenBalance(state, data) {
            state.fromTokenBalance = data;
        },
        setToTokenBalance(state, data) {
            state.toTokenBalance = data;
        },
        setContractFromObj(state, data) {
            state.contractFromObj = data;
        },
        setContractToObj(state, data) {
            state.contractToObj = data;
        },
        setAmountTo(state, data) {
            state.amountTo = data;
        },
        setAmountFrom(state, data) {
            state.amountFrom = data;
        },
        setSwapRate(state, data) {
            state.swapRate = data;
        },
        setMinimumTo(state, data) {
            state.minimumTo = data;
        },
        setContractObjDecimal(state, data) {
            data.isTo ? state.contractToObj.decimals = data.decimals : state.contractFromObj.decimals = data.decimals

        },

    },
    actions: {

    }
}

export default exchangeModule
<template>
	<div class="menu-container flexdiv" style="transform: scale(.7);width: 136%; margin-left: -18%;">
		
			<v-toolbar-title class="logoflex" style="overflow: visible;">
			     <a href="/" style="text-decoration: none;">
				<div class="logo" style="font-size: 40px;display: flex;flex-direction: row;justify-content: flex-start;"
				> Flame<img src="dex.png" style="border-style: none; width: 40px; height: 62px; position: relative;"
				>DE<mark style="background-color: transparent;font-size: 40px;">X</mark></div>
			      </a>
			</v-toolbar-title>
			
			<v-spacer/>			
			
			<v-connect-wallet/>
			<v-disconnect-wallet/>
			<div style="width:20px"></div>
			<v-settings/>

			
		
	</div>
</template>


<script>
    import ConnectWallet from '@/components/settings/ConnectWallet.vue'
    import DisconnectWallet from '@/components/settings/DisconnectWallet.vue'
    import Settings from '@/components/settings/Settings.vue'

    export default {
        name: 'Menu',
        props: {},
        components: {
            'v-connect-wallet': ConnectWallet,
            'v-disconnect-wallet': DisconnectWallet,
            'v-settings': Settings,
        },
        mixins: [],
        mounted() {


        },
        destroyed() {
        },

        computed: {},
        methods: {},


    }
</script>


<style lang="scss" scoped>
	.menu-container {
		/* margin-top: 70px; */
	}

	.logo {
		font-size: 60px;
		color: white;
		font-weight: 700;
	}

	.logo > mark {
		color: #D64B78;
		background-color: transparent;
		margin: 0;
	}


</style>

const messageModule = {
    state: () => ({
        message: null,
    }),
    mutations:{
        addMessage(state, data) {
            state.message = data;
        },
        removeMessage(state) {
            state.message = null;
        },
    },

};

export default messageModule
function isNotBlank(strVal) {
  return strVal !== undefined && strVal !== null && strVal.trim().length > 1;
}

export class RouteBuilder {
  constructor(amt, contractAddr) {
    this.amt = amt;
    this.contractAddr = contractAddr;
    this.route = []
    this.transition(contractAddr)
  }

  transition(addr) {
    this.route.push(this._makeTransition(addr))
    return this;
  }

  iSwap(sParams) {
    this.route.push(this._makeInnerSwap(sParams))
    return this;
  }

  eSwap(sParams) {
    this.route.push(this._makeOuterSwap(sParams))
    return this;
  }


  _makeRouteHop(fwd, bucketId) {
    return {
      "prim": "Right",
      "args": [
        {
          "prim": "Pair",
          "args": [
            {
              "prim": fwd ? "True" : "False"
            },
            {
              "int": "" + bucketId
            }
          ]
        }
      ]
    }
  }

  _makeTransition(addr) {
    return {
      "prim": "Right",
      "args": [
        {
          "string": "" + addr
        }
      ]
    }
  }

  /*outerSwapParamsExample = {
    externalAddr: "",
    minOut: "",
    fwd: true,
    bucketId: 0
  }*/
  _makeInnerSwap(params) {
    return {
      "prim": "Left",
      "args": [
        {
          "prim": "Pair",
          "args": [
            {
              "prim": "Pair",
              "args": [
                isNotBlank(params.externalAddr) ? { "prim": "Some", "args": [{ "string": params.externalAddr }] } : { "prim": "None" },
                params.minOut !== null && params.minOut !== undefined ? { "prim": "Some", "args": [{ "int": params.minOut }] } : { "prim": "None" },
              ]
            },
            {
              "prim": "Right",
              "args": [
                {
                  "prim": "Pair",
                  "args": [
                    {
                      "int": "" + params.bucketId
                    },
                    {
                      "prim": params.fwd ? "True" : "False"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
  /*const outerSwapParamsExample = {
    externalAddr: "",
    minOut: "",
    fromToken: {
      addr: "",
      id: ""
    },
    fromToken: {
      addr: ""
    }
  }*/
  _makeOuterSwap(params) {
    return {
      "prim": "Left",
      "args": [
        {
          "prim": "Pair",
          "args": [
            {
              "prim": "Pair",
              "args": [
                isNotBlank(params.externalAddr) ? { "prim": "Some", "args": [{ "string": params.externalAddr }] } : { "prim": "None" },
                params.minOut !== null && params.minOut !== undefined ? { "prim": "Some", "args": [{ "int": params.minOut }] } : { "prim": "None" },
              ]
            },
            {
              "prim": "Left",
              "args": [
                {
                  "prim": "Pair",
                  "args": [
                    {
                      "prim": params.fromToken.id !== null && params.fromToken.id !== undefined ? "Right" : "Left",
                      "args": [
                        {
                          "prim": "Pair",
                          "args":
                            params.fromToken.id !== null && params.fromToken.id !== undefined ? (
                              [

                                {
                                  "string": params.fromToken.addr
                                },
                                {
                                  "int": "" + params.fromToken.id
                                }
                              ]) : [{ "string": params.fromToken.addr }]
                        }
                      ]
                    },
                    {
                      "prim": params.toToken.id !== null && params.toToken.id !== undefined ? "Right" : "Left",
                      "args": [
                        {
                          "prim": "Pair",
                          "args":
                            params.toToken.id !== null && params.toToken.id !== undefined ? (
                              [

                                {
                                  "string": params.toToken.addr
                                },
                                {
                                  "int": "" + params.toToken.id
                                }
                              ]) : [{ "string": params.toToken.addr }]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }

  _toArgs() {
    return {
      "prim": "Pair",
      "args": [
        {
          "int": "" + this.amt
        },
        this.route,
      ]
    }
  }

  toTransferParams() {
    return {
      amount: 0,
      mutez: false,
      to: this.contractAddr,
      parameter: {
        entrypoint: "swap",
        value: this._toArgs()
      }
    }
  }
}
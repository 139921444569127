<template>
  <div class="" v-if="!PKH">
    <v-btn
      v-on:click="onConnect"
      
      color="#9575cd"
      height="48"
      min-width="210"
      x-large
      rounded
      style="
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        letter-spacing: normal;
        font-size: 20px;
      "
      >Connect wallet
    </v-btn>
  </div>
</template>

<script>
import { BeaconWallet } from "@taquito/beacon-wallet";
import { InMemorySigner } from "@taquito/signer";

import { MichelCodecPacker, TezosToolkit } from "@taquito/taquito";
import { Tzip16Module } from "@taquito/tzip16";
import { Tzip12Module } from "@taquito/tzip12";

export class ReadOnlySigner {
  constructor(pkh, pk) {}

  async publicKeyHash() {
    return this.pkh;
  }

  async publicKey() {
    return this.pk;
  }

  async secretKey() {
    console.log("Secret key cannot be exposed");
  }

  async sign() {
    console.log("Cannot sign");
  }
}

export default {
  name: "ConnectWallet",
  props: {},
  components: {},
  mixins: [],
  async mounted() {
    await this.initWallet();
    await this.checkIfActiveUser();
  },
  destroyed() {},
  computed: {
    PKH() {
      return this.$store.state.auth.PKH;
    },
    tezos() {
      return this.$store.state.global.tezos;
    },
  },
  data() {
    return {
      wallet: null,
    };
  },
  methods: {
    async setProviders(tezos) {
      tezos.setWalletProvider(this.wallet);
      tezos.setSignerProvider(
        await InMemorySigner.fromSecretKey(
          "edsk3QoqBuvdamxouPhin7swCvkQNgq4jP5KZPbwWNnwdZpSpJiEbq"
        )
      ); // alice
      // Tezos.setSignerProvider(
      //     new ReadOnlySigner(activeAcc.address, activeAcc.publicKey)
      // );
      return tezos;
    },
    async initWallet() {
      this.wallet = new BeaconWallet({
        name: this.$store.state.global.settingsData.walletName,
        preferredNetwork:
          this.$store.state.global.settingsData.walletNetworkType,
      });

      this.$store.commit("setWallet", this.wallet);
    },
    async checkIfActiveUser() {
      const activeAccount = await this.wallet.client.getActiveAccount();

      if (activeAccount && this.tezos !== null) {
        this.setProviders(this.tezos);
        let userAddress = activeAccount.address;
        this.$store.commit("setPKH", userAddress);

        // const { tickets, validity } = await fetchUserTickets(
        //     userAddress,
        //     ticketerAddress
        // );
        // userTickets = tickets;
        // userTicketValidity = validity;
        // loadingProfile = false;
      }
    },
    async onConnect(url) {
      const activeAccount = await this.wallet.client.getActiveAccount();
      if (activeAccount) {
        await this.wallet.clearActiveAccount();
      }
      const network = {
        name: this.$store.state.global.settingsData.walletName,
        rpcUrl: this.$store.state.global.settingsData.rpcURL,
        type: this.$store.state.global.settingsData.walletNetworkType,
      };
      await this.wallet.requestPermissions({ network: network });
      const address = await this.wallet.getPKH();

      this.$store.commit("setPKH", address);

      let tezos = new TezosToolkit(
        this.$store.state.global.settingsData.rpcURL
      );
      tezos.setPackerProvider(new MichelCodecPacker());
      tezos.addExtension(new Tzip16Module());
      tezos.addExtension(new Tzip12Module());

      const activeAcc = await this.wallet.client.getActiveAccount();
      if (!activeAcc) {
        console.log("not connected");
      }

      this.setProviders(tezos);

      this.$store.commit("setWallet", this.wallet);
      this.$store.commit("setTezos", tezos);
    },
  },
};
</script>


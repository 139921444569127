
const liquidityRemoveModule = {
  namespaced: true,
  state: () => ({
    selectedToken: {},
    selectedBucket: null,
    selectedTokenAmount: null,
    selectedTokenShares: null,

    outputTokens: null,

    swapRate: null,
  }),
  mutations: {
    setSelectedBucket(state, data) {
      state.selectedBucket = data;
    },
    setSelectedTokenAmount(state, data) {
      state.selectedTokenAmount = data;
    },
    setSelectedTokenShares(state, data) {
      state.selectedTokenShares = data;
    },
    setSwapRate(state, data) {
      state.swapRate = data;
    },
    setOutputTokens(state, data) {
      state.outputTokens = data;
    },
  },
};

export default liquidityRemoveModule
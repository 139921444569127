import Vue from 'vue'
import Vuex from 'vuex'
import messageModule from "./modules/messages";
import globalModule from "./modules/global";
import authModule from "./modules/auth"
import exchangeModule from "./modules/exchange"
import liquidityAddModule from "./modules/liquidity-add"
import liquidityRemoveModule from "./modules/liquidity-remove"
import liquidityCreateModule from "./modules/liquidity-create"

Vue.use(Vuex);


export default new Vuex.Store({
    modules: {
        message: messageModule,
        global: globalModule,
        auth: authModule,
        exchange: exchangeModule,
        liquidityAdd: liquidityAddModule,
        liquidityRemove: liquidityRemoveModule,
        liquidityCreate: liquidityCreateModule,
    }
})

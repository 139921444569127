<template>
  <div
    class="discbut"
    v-if="PKH"
    style="display: flex; flex-direction: row; justify-content: flex-start"
  >
    <div
      v-if="!isLoading"
      style="
        height: 48px;
        min-width: 1px;
        border-color: #9575cd;
        color: #9575cd;
        font-weight: 600;
        font-size: 20px;
        border-radius: 20px;
        border-width: 2px;
        border-style: solid;
        align-items: center;
        display: flex;
        padding-left: 23px;
        padding-right: 23px;
        margin-top: 0px;
        position: relative;
      "
      class="btn"
    >
      {{ balance }}&nbsp;ꜩ
    </div>
    <div style="width: 20px"></div>
    <v-btn
      v-on:click="onDisconnect"
      color="#9575cd"
      height="48"
      min-width="210"
      x-large
      rounded
      style="
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        letter-spacing: normal;
        font-size: 20px;
      "
      >{{ formattedPKH }}&nbsp;&nbsp;<img src="disconn.svg" />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ConnectWallet",
  props: {},
  components: {},
  mixins: [],
  mounted() {
  },
  destroyed() {},
  computed: {
    formattedPKH() {
      return this.$store.state.auth.formattedPKH;
    },
    balance() {
      if (this.$store.state.auth.balance) {
        return `${this.$store.state.auth.balance.toNumber() / 1000000}`;
      } else {
        return null
      }
      
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    onDisconnect() {
      this.$store.dispatch("disconnect");
    },
  },
};
</script>


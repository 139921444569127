<template>
  <v-app>
    <v-main class="main-container">
      <!--Menu-->
      <v-menu></v-menu>
      <router-view />    
    </v-main>
    <!--Footer-->
 

    <!--		<v-footer></v-footer>-->
    

    <!--Messages-->
    <v-messages ref="messages"></v-messages>

    <!--Preloader-->
    <v-preloader></v-preloader>
  </v-app>
</template>

<script>
import Preloader from "@/components/global/Preloader.vue";
import Menu from "@/components/global/Menu.vue";
import Footer from "@/components/global/Footer.vue";
import Messages from "@/components/global/Messages.vue";

import { MichelCodecPacker, TezosToolkit } from "@taquito/taquito";
import { Tzip16Module } from "@taquito/tzip16";
import { Tzip12Module } from "@taquito/tzip12";

export default {
  name: "App",
  components: {
    "v-menu": Menu,
    "v-footer": Footer,
    "v-preloader": Preloader,
    "v-messages": Messages,
  },
  data() {
    return {};
  },
  created() {
    this.setNetwork();
    this.getSmartContracts();
  },
  async mounted() {
    await this.initTezos();
  },
  computed: {},
  methods: {
    setNetwork() {
      if (window.location.hostname.indexOf('localhost') >= 0) {
        this.$store.commit("setNetwork", "localnet");
      }
      let networkLocalStorage = localStorage.getItem("network");
      if (!networkLocalStorage) {
        this.$store.commit("setNetwork", "mainnet");
      } else {
        this.$store.commit("setNetwork", networkLocalStorage);
      }
    },
    getSmartContracts() {
      this.$store.dispatch("getSmartContracts");
    },
    async initTezos() {
      let tezos = new TezosToolkit(
        this.$store.state.global.settingsData.rpcURL
      );
      tezos.setPackerProvider(new MichelCodecPacker());
      tezos.addExtension(new Tzip16Module());
      tezos.addExtension(new Tzip12Module());
      this.$store.commit("setTezos", tezos);
    },
  },
  watch: {},
};
</script>


<style lang="scss">
@import "@/scss/global.scss";

.v-btn {
  text-transform: none !important;
}
</style>

import store from '../store'

export default {
    methods: {
        errorMessage(message, error, parseError, timeoutBool){

            let strErrorCode = ''
            let strErrorMessage = ''
            if(error && parseError){
                if (error.response){
                    // strErrorMessage = JSON.stringify(error.response.data)
                    strErrorMessage = ''
                    strErrorCode = JSON.stringify(error.response.status)


                    if( typeof (error.response.data) === 'object'){
                        for (const [key, value] of Object.entries(error.response.data)) {
                            strErrorMessage += `${value}   `
                            // console.log(`${key}: ${value}`);
                        }
                    } else{
                        strErrorMessage = 'Error!'
                    }

                    console.log(error.response)
                    console.log(strErrorCode)
                } else if (error.title){
                    strErrorMessage = error.title
                    console.log(error.title)
                } else{
                    strErrorMessage = error

                }

            }

            console.log(error)

            if (message === ''){
                message = 'Error!'
            }

            this.$store.commit('addMessage', {
                status: 'error',
                text: message + ' '  + strErrorMessage,
                timeout: timeoutBool,
            })
        },
        infoMessage(message, timeoutBool){
            this.$store.commit('addMessage', {
                status: 'info',
                text: message,
                timeout: timeoutBool,
            })
        },
        successMessage(message, timeoutBool){
            this.$store.commit('addMessage', {
                status: 'success',
                text: message,
                timeout: timeoutBool,
            })
        },
        loadingMessage(message, timeoutBool){
            this.$store.commit('addMessage', {
                status: 'loading',
                text: message,
                timeout: timeoutBool,
            })
        },

    },
};



<template>
	<div class="container">

		<v-snackbar
				v-if="showSnackbar"
				v-model="showSnackbar"
				:color=getBgColor(message)
				:timeout="getTimeout(message)"
		>
			{{message.text}}

			<v-progress-circular
					v-if='message.status === "loading"'
					indeterminate
					color="white"
			></v-progress-circular>

			<template v-slot:action="{ attrs }">
				<v-btn
						:color="getBtnColor(message)"
						text
						v-bind="attrs"
						@click="removeMessage()"
						v-if="message.status !== 'loading'"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                showSnackbar:false
            }
        },
        mounted() {

        },
        computed: {
            message() {
                return this.$store.state.message.message
            },
        },
        methods: {
            getBgColor(message) {
                if (message.status === "loading") {
                    return 'blue '
                }
                if (message.status === "info") {
                    return 'blue '
                }
                if (message.status === "success") {
                    return 'green'
                }
                if (message.status === "error") {
                    return 'red darken-2'
                }
            },
            getTimeout(message) {
                return message.timeout ? 7000 : null
            },
            getBtnColor(message) {
                if (message.status === "loading") {
                    return 'white'
                }
                if (message.status === "info") {
                    return 'white'
                }
                if (message.status === "success") {
                    return 'white'
                }
                if (message.status === "error") {
                    return 'white'
                }
            },
            removeMessage() {
                this.$store.commit('removeMessage')
            },
        },
        watch: {
            message(newValue){
                this.showSnackbar = newValue
            },
        },
    }
</script>

<style lang="scss" scoped>

	@import "@/scss/includes/_variables.scss";


	.container {
		position: fixed;
		right: 20px;
		z-index: 10000;
		bottom: 20px;
		/*background-color: red;*/
		display: flex;
		flex-direction: column-reverse;
	}


	.message {
		overflow-y: hidden;

	}

	.messages-move {
		transition: transform 0.5s;

	}

	.messages-enter-active, .messages-leave-active {
		transition: all 0.7s;

	}

	.messages-enter, .messages-leave-to {
		opacity: 0;
		transform: translateX(30px);
	}


</style>

import { RouteBuilder } from "./RouteBuilder";
import { BigNumber } from "bignumber.js"

export class Dex {
  constructor(tezos, wallet, lambdaView, uberPool) {
    this.tezos = tezos;
    this.wallet = wallet;
    this.lambdaView = lambdaView;
    this.uberPool = uberPool;
  }

  async getTokenBalance(addr, tokenContract) {
    //console.log(`addr: ${addr}, contract: ${tokenContract}]`)
    const contract = await this.tezos.contract.at(tokenContract)
    const balance = contract.views.balance_of([{ owner: addr, token_id: 0 }]);
    const val = await balance.read(this.lambdaView ? this.lambdaView : undefined);
    console.log(val[0].balance.toString());
    return val[0].balance;
  }

  approveToken(token, tokenContract, from, to, amount) {
    if (token.type === 'fa2') {
      return tokenContract.methods.update_operators([
        {
          add_operator: {
            owner: from,
            operator: to,
            token_id: token.fa2Id,
          },
        },
      ]);
    } else if (token.type === 'fa12') {
      return tokenContract.methods.approve(to, amount);
    }
  }

  /*_makePathParam(fwd, bucketId) {
    return {
      "prim": "Right",
      "args": [
        {
          "prim": "Pair",
          "args": [
            {
              "prim": fwd ? "True" : "False"
            },
            {
              "int": "" + bucketId
            }
          ]
        }
      ]
    }
  }*/

  async makeSwap(PKH, fromTokenInfo, route, minOut) {
    console.log(`Making swap: minOut=${minOut}`)
    const op = await this._genSwap(PKH, route, minOut,  fromTokenInfo.type === 'tz');
    //const contract = await this.tezos.wallet.at(
    //  this.uberPool
    //);
    console.log("UberPool: " + this.uberPool)
    const contractRoute = route.slice(1).map(p => {
      return { i: [p.bucketId, p.fwd] }
    });
    console.log(contractRoute)
    console.log(`Owner: ${PKH}`)

    let batch = this.tezos.wallet.batch([]);
    if (fromTokenInfo.type !== 'tz') {
      const tokenContract = await this.tezos.wallet.at(fromTokenInfo.address)
      batch
        .withTransfer(
          this.approveToken(
            fromTokenInfo,
            tokenContract,
            PKH,
            this.uberPool,
            "" + route[0].amt
          ).toTransferParams()
      );
    }
    /*const operation = await this.wallet.sendOperations([
      {
        kind: "transaction",
        destination: this.uberPool,
        amount: "0",
        parameters: {
            entrypoint: "swap",
            value: op
        }
      }
    ])*/
    batch.withTransfer(op)

    const operation = await batch.send();
    await operation.confirmation()
  }

  async _genSwap(pkh, route, minOut, isTezosIn) {
    console.log(`Route: ${JSON.stringify(route)}`)
    //const routeBuilder = new RouteBuilder(route[0].amt, this.uberPool)
    const callParam = route.slice(1).map(p => [p.bucketId, p.fwd])
    console.log(`Route: ${JSON.stringify(callParam)}`)
    const contract = await this.tezos.wallet.at(this.uberPool);
    return contract.methods.swap("" + route[0].amt, minOut, pkh, callParam).toTransferParams({mutez: true, amount: isTezosIn ? route[0].amt : 0});
  }

  _adjustWithST(val, slippageTolerance) {
    if (!val) throw new Error('Value is null');
    return val.minus(val.multipliedBy(slippageTolerance / 100));
  }

  _adjustMaxWithST(val, slippageTolerance) {
    if (!val) throw new Error('Value is null');
    return val.plus(val.multipliedBy(slippageTolerance / 100));
  }

  async addLiquidity(PKH, bucket, aTokenInfo, bTokenInfo, aTokenAmount, bTokenAmount, slippageTolerance) {
    console.log(`addLiquidity, bucketId=${bucket.bucketId}, A=${aTokenAmount.decimalPlaces(0)}, B=${bTokenAmount.decimalPlaces(0)}, aTokenInfo=${JSON.stringify(aTokenInfo)}, aTokenInfo=${JSON.stringify(bTokenInfo)}`)
    const contract = await this.tezos.wallet.at(
      this.uberPool
    );

    aTokenAmount = aTokenAmount.decimalPlaces(0);
    bTokenAmount = bTokenAmount.decimalPlaces(0);

    // recalculate amounts based on dex formula
    if (aTokenAmount.isLessThanOrEqualTo(bTokenAmount) || aTokenInfo.type === 'tz') {
      bTokenAmount = aTokenAmount.multipliedBy(bucket.reserveB).div(bucket.reserveA).integerValue(BigNumber.ROUND_UP);
    } else {
      aTokenAmount = bTokenAmount.multipliedBy(bucket.reserveA).div(bucket.reserveB).integerValue(BigNumber.ROUND_UP);
    }

    let batch = this.tezos.wallet.batch([]);
    let aTokenContract;
    if (aTokenInfo.type !== 'tz') {
      aTokenContract = await this.tezos.wallet.at(aTokenInfo.address)
      batch
        .withTransfer(
          this.approveToken(
            aTokenInfo,
            aTokenContract,
            PKH,
            this.uberPool,
            aTokenAmount
          ).toTransferParams()
        );
    }
    const bTokenContract = await this.tezos.wallet.at(bTokenInfo.address)
    batch
      .withTransfer(
        this.approveToken(
          bTokenInfo,
          bTokenContract,
          PKH,
          this.uberPool,
          bTokenAmount
        ).toTransferParams()
      );
    batch
      .withTransfer(
        contract.methods
          .addLiquidity(
            bucket.bucketId,
            aTokenAmount,
            bTokenAmount,
            this._adjustMaxWithST(aTokenAmount, slippageTolerance).decimalPlaces(0),
            this._adjustMaxWithST(bTokenAmount, slippageTolerance).decimalPlaces(0)
          ).toTransferParams({amount: aTokenInfo.type === 'tz' ? aTokenAmount.decimalPlaces(0) : 0, mutez: true})
      )
    
    if (aTokenInfo.type === 'fa12') {
      batch
      .withTransfer(
        this.approveToken(
          aTokenInfo,
          aTokenContract,
          PKH,
          this.uberPool,
          0
        ).toTransferParams()
      );
    }

    if (bTokenInfo.type === 'fa12') {
      batch
      .withTransfer(
        this.approveToken(
          bTokenInfo,
          bTokenContract,
          PKH,
          this.uberPool,
          0
        ).toTransferParams()
      );
    }
    /*const res = await contract.methods
      .addLiquidity(
        bucketId,
        lpTokenAmount.decimalPlaces(0),
        aTokenAmount.decimalPlaces(0),
        bTokenAmount.decimalPlaces(0)
      )
      .send();*/
    const operation = await batch.send();
    await operation.confirmation()
  }

  async remLiquidity(bucketId, lpTokenAmount, aTokenAmount, bTokenAmount, slippageTolerance) {
    const contract = await this.tezos.wallet.at(
      this.uberPool
    );

    const operation = await contract.methods
      .remLiquidity(
        bucketId,
        lpTokenAmount.decimalPlaces(0),
        this._adjustWithST(aTokenAmount, slippageTolerance).decimalPlaces(0),
        this._adjustWithST(bTokenAmount, slippageTolerance).decimalPlaces(0)
      )
      .send();
    await operation.confirmation()
  }

  async createBucket(PKH, aTokenInfo, bTokenInfo, aTokenAmount, bTokenAmount) {
    const contract = await this.tezos.wallet.at(
      this.uberPool
    );
    let batch = this.tezos.wallet.batch([]);
    if (aTokenInfo.type !== 'tz') {
      const aTokenContract = await this.tezos.wallet.at(aTokenInfo.address)
      batch
        .withTransfer(
          this.approveToken(
            aTokenInfo,
            aTokenContract,
            PKH,
            this.uberPool,
            aTokenAmount.decimalPlaces(0)
          ).toTransferParams()
        );
    }
    const bTokenContract = await this.tezos.wallet.at(bTokenInfo.address)
    batch
      .withTransfer(
        this.approveToken(
          bTokenInfo,
          bTokenContract,
          PKH,
          this.uberPool,
          bTokenAmount.decimalPlaces(0)
        ).toTransferParams()
      );
    aTokenAmount = aTokenAmount.decimalPlaces(0),
    bTokenAmount = bTokenAmount.decimalPlaces(0)
    let initBucketTransfer;
    if (aTokenInfo.type == "fa2" && bTokenInfo.type == "fa2") {
      initBucketTransfer = contract.methods.initBucket("fa2", aTokenInfo.address, aTokenInfo.fa2Id, aTokenAmount.decimalPlaces(0), "fa2", bTokenInfo.address, bTokenInfo.fa2Id, bTokenAmount.decimalPlaces(0)).toTransferParams()
    } else if (aTokenInfo.type == "fa2" && bTokenInfo.type == "fa12") {
      initBucketTransfer = contract.methods.initBucket("fa2", aTokenInfo.address, aTokenInfo.fa2Id, aTokenAmount.decimalPlaces(0), "fa12", bTokenInfo.address, bTokenAmount.decimalPlaces(0)).toTransferParams()
    } else if (aTokenInfo.type == "fa12" && bTokenInfo.type == "fa2") {
      initBucketTransfer = contract.methods.initBucket("fa12", aTokenInfo.address, aTokenAmount.decimalPlaces(0), "fa2", bTokenInfo.address, bTokenInfo.fa2Id, bTokenAmount.decimalPlaces(0)).toTransferParams()
    } else if (aTokenInfo.type == "fa12" && bTokenInfo.type == "fa12") {
      initBucketTransfer = contract.methods.initBucket("fa12", aTokenInfo.address, aTokenAmount.decimalPlaces(0), "fa12", bTokenInfo.address, bTokenAmount.decimalPlaces(0)).toTransferParams()
    } else if (aTokenInfo.type == "tz" && bTokenInfo.type == "fa2") {
      initBucketTransfer = contract.methods.initBucket("tz", 'unit', aTokenAmount.decimalPlaces(0), "fa2", bTokenInfo.address, bTokenInfo.fa2Id, bTokenAmount.decimalPlaces(0)).toTransferParams({mutez: true, amount: aTokenAmount})
    } else if (aTokenInfo.type == "tz" && bTokenInfo.type == "fa12") {
      initBucketTransfer = contract.methods.initBucket("tz", 'unit', aTokenAmount.decimalPlaces(0), "fa12", bTokenInfo.address, bTokenAmount.decimalPlaces(0)).toTransferParams({mutez: true, amount: aTokenAmount})
    } else {
      throw new Error('Invalid token combination')
    }
    batch.withTransfer(initBucketTransfer)
    const operation = await batch.send();
    await operation.confirmation()
  }
}